(function($){

    let _verboseLogging = false;

    function show_search(){
        let $searchCollapse = $('#search-collapse');
        $searchCollapse.collapse('show');
        $searchCollapse.find('input').focus();

    }

    function show_login(){
        let $loginCollapse = $('#login-collapse');
        $loginCollapse.collapse('show');
        $loginCollapse.find('.login-user-name').focus();
    }

    (function(){



        /* Old Menu Stuff Below
        * */

        let $loginOverlay = $('#login-with-overlay');
        let $searchOverlay = $('#search-with-overlay');
        let $dropdownToggle = $('.nav-item.dropdown');


        $loginOverlay.mouseenter(function(){

            if(_verboseLogging){
                console.log('show the login overlay');
            }

            setTimeout(show_login, 100);
        });

        $loginOverlay.mouseleave(function(){

            if(_verboseLogging){
                console.log('hiding the login overlay');
            }

            $('#login-collapse').collapse('hide');
        });

        $searchOverlay.mouseenter(function(){

            if(_verboseLogging){
                console.log('show the search overlay');
            }

            setTimeout(show_search, 100);
        });

        $searchOverlay.mouseleave(function(){

            if(_verboseLogging){
                console.log('hiding the search overlay');
            }

            $('#search-collapse').collapse('hide');
        });


        $dropdownToggle.on('mouseenter', function(e){
            if(_verboseLogging){
                console.log('showing dropdown');
            }

           $(this).find('.dropdown-toggle').dropdown('show');
        });

        $dropdownToggle.on('mouseleave', function(e){
            if(_verboseLogging){
                console.log('hiding dropdown');
            }

           $(this).find('.dropdown-toggle').dropdown('hide');
        });

    })();

})(jQuery);